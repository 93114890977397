/* Importing Bootstrap SCSS file. */

@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */

.modal-holder {
    animation-name: example;
    animation-duration: 0.3s;
}

@keyframes example {
    0% {
        transform: scale(0.5)
    }
    100% {
        transform: scale(1)
    }
}

.carousel-item {
    transition: opacity 0.7s ease !important;
    position: absolute !important;
    display: block !important;
    opacity: 0;
}

.carousel-item.active {
    position: relative !important;
    opacity: 1;
}

.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
}

.custom-day.focused {
    background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
    background-color: #556ee6;
    color: white;
}

.custom-day.faded {
    background-color: #556ee6;
}